"use strict";

import "./js/inc/_jquery-global.js";
import "./scss/style.scss";

window.addEventListener("load", () => {
  /* スマホメニュー
  ----------------------------- */
  const hamburger = document.querySelector('.js-hamburger');
  const headerNav = document.querySelector('.js-headerNav');
  const headerBg = document.querySelector('.js-headerBg');
  const body = document.querySelector('body');

  hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('is-open');
    headerNav.classList.toggle('is-open');
    headerBg.classList.toggle('is-open');
    body.classList.toggle('is-open');
  });

  const closeMenu = () => {
    hamburger.classList.remove('is-open');
    headerNav.classList.remove('is-open');
    headerBg.classList.remove('is-open');
    body.classList.remove('is-open');
  }

  headerBg.addEventListener('click', () => {
    closeMenu();
  });

  /* header 固定・変化
  ----------------------------- */
  const headerHeight = $('.js-header').outerHeight(true);

  function headerChange() {
    const scroll = $(window).scrollTop();
    if (scroll >= headerHeight) {
      $('.js-header').addClass('is-fixed');
    } else {
      $('.js-header').removeClass('is-fixed');
    }
  }

  $(window).on('scroll', function () {
    headerChange();
  });

  /* FV スライダー
  ----------------------------- */
  const recommendSlider = document.querySelector('.js-fvSlider');
  if(recommendSlider){
    const mySwiper = new Swiper('.js-fvSlider', {
      slidesPerView: 1,
      loop: true,
      loopAdditionalSlides: 1,
      speed: 1000,
      spaceBetween: 15,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.fv__slider .swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        576: {
          slidesPerView: 1.15,
          spaceBetween: 20,
          centeredSlides : true,
        },
        768: {
          slidesPerView: 1.15,
          spaceBetween: 30,
          centeredSlides : true,
        },
      },
    })
  }

  /* 事例紹介 スライダー
  ----------------------------- */
  const caseSlider = document.querySelector('.js-caseSlider');
  const caseSliderWrap = document.querySelector('.js-caseSliderContainer');
  if(caseSlider) {
    const slideLength = document.querySelectorAll('.case__slide').length;
    if(slideLength > 1) {
      const caseSwiper = new Swiper('.js-caseSlider', {
        slidesPerView: 1,
        loop: true,
        loopAdditionalSlides: 1,
        speed: 1000,
        spaceBetween: "4%",
        grabCursor: true,
        navigation: { 
          nextEl: '.js-caseSliderContainer .js-caseSlider-button-next',
          prevEl: '.js-caseSliderContainer .js-caseSlider-button-prev',
        },
        pagination: {
          el: '.js-caseSliderContainer .swiper-pagination',
        },
        breakpoints: {
          576: {
            slidesPerView: 1.25,
            spaceBetween: "3.5%",
            loop: true,
            centeredSlides : true,
            loopAdditionalSlides: 1,
          },
          768: {
            slidesPerView: 1.5,
            spaceBetween: "3.5%",
            loop: true,
            centeredSlides : true,
            loopAdditionalSlides: 1,
          },
          992: {
            slidesPerView: 1.95,
            spaceBetween: "3.5%",
            centeredSlides : true,
            loop: true,
            loopAdditionalSlides: 1,
          },
        },
      });
    } else {
      caseSliderWrap.classList.add('is-stop');
    }
  }

  /* コラム スライダー
  ----------------------------- */
  const columnSlider = document.querySelector('.js-columnSlider');
  if(columnSlider) {
    const columnSwiper = new Swiper('.js-columnSlider', {
      slidesPerView: 1,
      spaceBetween: "4%",
      loop: true,
      loopAdditionalSlides: 1,
      speed: 1000,
      grabCursor: true,
      navigation: { 
        nextEl: '.js-columnSliderContainer .swiper-button-next',
        prevEl: '.js-columnSliderContainer .swiper-button-prev',
      },
      breakpoints: {
        460: {
          slidesPerView: 1.5,
          spaceBetween: "3%",
          centeredSlides : true,
          loop: true,
          navigation: { 
            nextEl: '.js-columnSliderContainer .swiper-button-next',
            prevEl: '.js-columnSliderContainer .swiper-button-prev',
          },
        },
        768: {
          slidesPerView: 2.5,
          spaceBetween: "3%",
          loop: false,
          navigation: { 
            nextEl: '.js-columnSliderBtnHead .swiper-button-next',
            prevEl: '.js-columnSliderBtnHead .swiper-button-prev',
          },
        },
        1200: {
          slidesPerView: 3.5,
          spaceBetween: "2%",
          loop: false,
          navigation: { 
            nextEl: '.js-columnSliderBtnHead .swiper-button-next',
            prevEl: '.js-columnSliderBtnHead .swiper-button-prev',
          },
        },
      },
    });
  }

  /* チーム紹介 スライダー
  ----------------------------- */
  const teamSlider = document.querySelector('.js-teamSlider');
  const teamSliderContainer = document.querySelector('.js-teamSliderContainer');
  if(teamSlider) {
    const slideLength = document.querySelectorAll('.recruit__team_slide').length;
    if(slideLength > 2){
      const teamSwiper = new Swiper('.js-teamSlider', {
        slidesPerView: 1,
        spaceBetween: "4%",
        loop: true,
        loopAdditionalSlides: 1,
        speed: 1000,
        grabCursor: true,
        navigation: { 
          nextEl: '.js-teamSliderContainer .swiper-button-next',
          prevEl: '.js-teamSliderContainer .swiper-button-prev',
        },
        breakpoints: {
          576: {
            slidesPerView: 2,
            spaceBetween: "3%",
            navigation: { 
              nextEl: '.js-teamSliderContainer .swiper-button-next',
              prevEl: '.js-teamSliderContainer .swiper-button-prev',
            },
          },
          920: {
            slidesPerView: 3,
            spaceBetween: "3%",
            navigation: { 
              nextEl: '.js-teamSliderContainer .swiper-button-next',
              prevEl: '.js-teamSliderContainer .swiper-button-prev',
            },
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: "2%",
            navigation: { 
              nextEl: '.js-teamSliderContainer .swiper-button-next',
              prevEl: '.js-teamSliderContainer .swiper-button-prev',
            },
          },
        },
      });
    } else {
      teamSliderContainer.classList.add('is-stop');
    }
  }

  /* コラム 目次
  -------------------------------------------- */
  const outlineElements = document.querySelectorAll('.js-outline');

  const outline = (TOC_INSERT_SELECTOR) => {
    const HEADING_SELECTOR    = '.js-content h2'; // [セレクター指定] 収集する見出し要素 querySelectorAll用
    const LINK_CLASS_NAME     = 'tocLink';           // [クラス名] 目次用aタグに追加するクラス名     .無し
    const ID_NAME             = 'heading';           // [ID名]    目次に追加するID名のプレフィックス #無し
    const tocInsertElement    = document.querySelector(TOC_INSERT_SELECTOR);
    const headingElements     = document.querySelectorAll(HEADING_SELECTOR);
    const layer = [];
    let id = 0;
    const uid   = () =>`${ID_NAME}${id++}`;
    let links = null;
    let oldRank = -1;
    try {
      const createLink = (el) => {
        let li = document.createElement('li');
        let a  = document.createElement('a');
        el.id  = el.id || uid();
        a.href = `#${el.id}`;
        a.innerText = el.innerText;
        a.className = LINK_CLASS_NAME;
        li.appendChild(a);
        return li;
      };

      const findParentElement = (layer, rank, diff) => {
        do {
          rank += diff;
          if (layer[rank]) return layer[rank];
        } while (0 < rank && rank < 7);
        return false;
      };

      const appendToc = (el, toc) => {
        el.appendChild(toc.cloneNode(true));
      };
      
      // 目次ハイライト
      const tocHighlight = (e) => {
        const sy = window.pageYOffset;
        const ey = sy + document.documentElement.clientHeight;
        let tocHighlightEl = null;
        links.forEach( (el) => {
          const targetEl = document.querySelector(el.hash);
          const y = sy + targetEl.getBoundingClientRect().top ;
          el.classList.remove("is-current") ;
          if(sy < y &&  y < ey)tocHighlightEl = el;
          if(sy > y) tocHighlightEl = el;
      });
        if(tocHighlightEl)tocHighlightEl.classList.add("is-current");
      };

      headingElements.forEach( (el) => {
        let rank   = Number(el.tagName.substring(1));
        let parent = findParentElement(layer, rank, -1);
        if (oldRank > rank) layer.length = rank + 1;
        if (!layer[rank]) {
            layer[rank] = document.createElement('ol');
            if (parent.lastChild) parent.lastChild.appendChild(layer[rank]);
        }
        layer[rank].appendChild(createLink(el));
        oldRank = rank;
      });

      if (layer.length) appendToc(tocInsertElement, findParentElement(layer, 0, 1));
      links = document.querySelectorAll(`.${LINK_CLASS_NAME}`);
      links.forEach((el) => {
        el.addEventListener("click", function(e) {
          e.preventDefault();
          e.stopPropagation();
      
          let href = this.getAttribute("href");
          let target = href === "#" ? document.body : document.querySelector(href);
          let position = target.getBoundingClientRect().top + window.pageYOffset - 100;
      
          window.scrollTo({
            top: position,
            behavior: "smooth"
          });
        });
      });

      if(TOC_INSERT_SELECTOR === '.js-outlinePC'){
        tocHighlight();
        window.addEventListener("scroll", tocHighlight);
      }
    } catch (e) {
      console.error(e);
    }
  }

  outlineElements.forEach((outlineElement) => {
    if(outlineElement) {
      outline('.js-outlinePC');
      outline('.js-outlineSP');

      if(outlineElement.children.length === 0){
        const outlineContainers = document.querySelectorAll('.js-outlineContainer');
        outlineContainers.forEach((outlineContainer) => {
          outlineContainer.style.display = 'none';
        });
      }
    } 
  })

  /* よくある質問
  -------------------------------------------- */
  const faq = document.querySelector('.js-faq');
  if(faq) {
    $(".js-faqBox:first-of-type .js-faqAnswer").css("display", "block");
    $(".js-faqBox:first-of-type .js-faqAnswer").addClass("is-active");
    $(".js-faqBox:first-of-type .js-faqQuestion").addClass("is-active");
  
    $(".js-faqQuestion").on("click", function () {
      $(".js-faqQuestion").not(this).next().slideUp(400);
      $(".js-faqQuestion").not(this).next().removeClass("is-active");
      $(".js-faqQuestion").not(this).removeClass("is-active");
      $(this).next().slideToggle(400);
      $(this).next().toggleClass("is-active");
      $(this).toggleClass("is-active");
    });
  }

  /* モーダルウィンドウ
  -------------------------------------------- */
  const modal = document.querySelector(".js-modal");
  if(modal) {
    MicroModal.init({
      openClass: 'is-modalOpen',
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      disableFocus: true,
    });
  }

  /* TOPに戻るボタン表示
  ----------------------------- */
  window.addEventListener('scroll', () => {
    const pageTop = document.querySelector('.js-pageTop');
    const currentY = window.pageYOffset;

    if(currentY >= 200) {
      setTimeout(() => {
        pageTop.style.opacity = 1;
        pageTop.style.visibility = 'visible';
      }, 1);
    } else {
      setTimeout(() => {
        pageTop.style.opacity = 0;
        pageTop.style.visibility = 'hidden';
      }, 1);
    }
  });
});

/* スクロールエフェクト
-------------------------------------------- */
window.addEventListener("load", function () {
  function animateOnScroll() {
    const wHeight = $(window).height();
    const scrollAmount = $(window).scrollTop();
    $('.effect').each(function () {
      const targetPosition = $(this).offset().top;
      if (scrollAmount > targetPosition - wHeight + 100 || scrollAmount + wHeight >= targetPosition) {
        $(this).addClass("is-animated");
      }
    });
  }

  // ページ読み込み時とスクロール時にアニメーションをチェック
  $(window).on("scroll load", animateOnScroll);

  // ページアクセス時にもアニメーションを実行
  animateOnScroll();
});